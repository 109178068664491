// window.localStorage.setItem('path', 'null')
const p: any = window.localStorage.getItem("path");
// console.log(p);
const path: any = {
  // dev: p ? `http://${p}` : "http://192.168.10.200:9000",
  dev: p ? `http://${p}:9000` : window.location.origin,
  save: "",
};
// console.log(path.dev);
export default path;

import request from '@/utils/request'
import qs from 'qs'
import { ElMessage,ElLoading } from 'element-plus'
import router from "@/router";

//未登录函数复用
function CheckToken(res:any) {
    if (res.data.code == -1001 || res.data.code == -1002) {
        window.localStorage.removeItem('token')
        window.sessionStorage.removeItem('token')
        ElMessage.closeAll()
        ElMessage({
            type: 'warning',
            message: '登录失效，请重新登录',
            duration: 1500,
        })
        router.push('/login')
    } else if (res.data.code != 200 && res.data.code != 201) {
        ElMessage({
            type: 'warning',
            message: res.data.msg,
            duration: 1500
        })
    }
}

//公共请求
function PublicRequest({
    path = '',
    data = '',
    method = 'get',
    loading = true,
    loadTitle = '加载',
    returnTree = false,
    sortType = 'desc',
    fileDownload = false,
    idName = 'id',
    pidName = 'pId',
    childName = 'child',
    fatherName = 'name',
}) {
    let load:any
    if (loading) {
        load = ElLoading.service({
            lock: true,
            text: "正在" + loadTitle,
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.1)",
        });
    }
    return new Promise((resolve, reject) => {
        if (method.toLocaleUpperCase() == 'GET') {
            // console.log(data);
            // console.log(qs.stringify(data));
            request.get(`${path}?${qs.stringify(data)}`).then((res:any) => {
                CheckToken(res)
                if (res.data.code == 200 || res.data.code == 201) {
                    if (returnTree == true) {
                        if (res.data.data.data) {
                            let treeData = MakeTreeData(sortType, res.data.data.data, 0, idName, pidName, childName, fatherName)
                            res.data.data.treeData = treeData;
                        } else {
                            let treeData = MakeTreeData(sortType, res.data.data, 0, idName, pidName, childName, fatherName)
                            res.data.treeData = treeData;
                        }
                    }
                    resolve(res)
                }
                if (loading == true) {
                    load.close()
                }
            }).catch((err:any) => {
                if (loading == true) {
                    load.close()
                }
                ElMessage.closeAll()
                ElMessage({
                    showClose: true,
                    type: 'error',
                    message: '网络错误，请刷新后重试',
                    duration: 5000,
                })
                reject(err)
            })
        } else if (method.toLocaleUpperCase() == 'POST') {
            request.post(path, data).then((res:any) => {
                CheckToken(res)
                if (res.data.code == 200 || res.data.code == 201) {
                    if (returnTree == true) {
                        if (res.data.data.data) {
                            let treeData = MakeTreeData(sortType, res.data.data.data, 0, idName, pidName, childName, fatherName)
                            res.data.data.treeData = treeData;
                            // console.log(treeData);
                        } else {
                            let treeData = MakeTreeData(sortType, res.data.data, 0, idName, pidName, childName, fatherName)
                            res.data.treeData = treeData;
                        }
                    }
                    resolve(res)
                }
                if (loading == true) {
                    load.close()
                }
            }).catch((err:any) => {
                if (loading == true) {
                    load.close()
                }
                ElMessage.closeAll()
                ElMessage({
                    showClose: true,
                    type: 'error',
                    message: '网络错误，请刷新后重试',
                    duration: 5000,
                })
                reject(err)
            })
        }
    })
}

//递归生成树形数据
function MakeTreeData(sortType:any, list:any, pid:any, idName:any, pidName:any, childName:any, fatherName:any, n:any = '', fName:any = '') {
    var arr:Array<any> = []
    list.forEach((item:any) => {
        if (item[pidName] == pid) {
            if (n == '' && fName == '') {
                item.fatherName = fName + n
            } else {
                item.fatherName = fName + n + '>'
            }
            const children = MakeTreeData(sortType, list, item[idName], idName, pidName, childName, fatherName, item[fatherName], item.fatherName)
            if (children.length) {
                item[childName] = children
            }
            if (sortType == 'desc') {
                arr.push(item)
            } else {
                arr.unshift(item)
            }
        }
    })
    return arr
}

// //递归生成树形数据
// function MakeTreeData(list, pid, idName, pidName, childName, fatherName, n = '') {
//     var arr = []
//     list.forEach(item => {
//         if (item[pidName] == pid) {
//             item.fatherName = n
//             const children = MakeTreeData(list, item[idName], idName, pidName, childName, fatherName, item[fatherName])
//             if (children.length) {
//                 item[childName] = children
//             }
//             arr.unshift(item)
//         }
//     })
//     return arr
// }

export { PublicRequest }




// 0 导入
import axios from 'axios'
import  path  from '@/utils/path'
// 1.创建实例
var request = axios.create({
	baseURL: path.dev, //默认的url
	timeout: 0,//请求超时
	headers: {
		//"Content-type": "application/x-www-form-urlencoded", name=zhangsan&age=18
		// "Content-type":"application/json" {name:'zhangsan',age:18}
		// post: {
		// 	"Content-type": "application/json"
		// }
	},
	// responseType: "arraybuffer",
})
// 2.配置拦截
// 请求开始
request.interceptors.request.use(
	function (config:any) {
		// console.log("开始加载", config.url, config.data, config.jiazai)
		if (localStorage.getItem('token')) {
			// 在请求头中添加token
			config.headers.token = localStorage.getItem('token');
		} else if (config.token) {
			//登陆时需要携带验证码的token请求
			config.headers.token = config.token;
		}
		return config;
	},
	// promise 里面有两个参数 reject resolve
	function (err:any) {
		return Promise.reject(err)
	})
// 请求结束
request.interceptors.response.use(
	function (res:any) {
		// console.log("结束加载",res)
		// Toast.clear()
		return res
	},
	// 处理错误
	function (err:any) {
		return Promise.reject(err)
	})
// jsonp 本身是一个发送请求的工具  就是以json的格式发送请求 
// request.jsonp = function (url, opts) {
// 	// 自己写一个promise
// 	return new Promise((resolve, reject) => {
// 		jsonp(url, opts, function (err, data) {
// 			if (err) { reject(err) }
// 			if (data) { resolve(data) }
// 		})
// 	})
// }

//3.抛出
export default request;

// test() { //实例
// 	request
// 	  .get(
// 		"/webus/getOneMywealth.html?mywealthOpenid=" + this.userInfo.openId,
// 		{ jiazai: { message: "正在加载..." } }
// 	  )
// 	  .then((res) => {
// 		console.log("asd" + res);
// 	  })
// 	  .catch((err) => {
// 		console.log(err);
// 	  });
//   },
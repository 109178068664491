import { PublicRequest } from '@/utils/PublicRequest'

//首页菜单栏
function GetMenuList(){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/Category/listall',
            method: 'get',
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//首页banner
function GetBannerList(data:any={p:1}) {
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/Ad/list',
            data:data,
            method: 'get',
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//获取下级标题
function GetMenuItem(data:any){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/Category/list',
            method: 'get',
            data:data,
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//获取友情链接
function GetLinkList(data:any){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/Links/list',
            method: 'get',
            data:data,
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//获取图片列表(首页资讯用)
function GetImgList(data:any){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: 'api/Article/imglists',
            method: 'get',
            data:data,
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export {GetMenuList,GetBannerList,GetMenuItem,GetLinkList,GetImgList}
<template>
  <router-view :key="cRoute" />
  <div class="footer">
    <div class="links">
      <img
        v-for="item in linkList"
        :key="item.id"
        :src="item.imgUrl"
        @click="goLink(item)"
      />
    </div>
    <img class="footImg" src="@/assets/img/footer.png" alt="" />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { GetLinkList } from "@/api/home";

const router = useRouter();
let cRoute = computed(() => {
  return router.currentRoute.value.fullPath;
});

let linkList = ref<any>([]); //友情链接列表

getLinkList(); //获取友情链接

//点击友情链接
const goLink = function (item: any) {
  // console.log(item);
  window.open(item.url, "_blank");
};

//友情链接
function getLinkList() {
  let data = { p: 1 };
  GetLinkList(data)
    .then((res: any) => {
      // console.log(res);
      linkList.value = res.data.data.data;
    })
    .catch((err: any) => {
      console.log(err);
    });
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  // background-color: #409eff;
  background-color: #183883;
  position: relative;
  .links {
    position: absolute;
    top: 0.1rem;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    // margin-bottom: 0.1rem;
    padding: 0.1rem 1.9rem 0;
    img {
      cursor: pointer;
      margin-right: 0.05rem;
      display: block;
      width: 0.74rem;
      height: 0.28rem;
    }
  }
  .footImg {
    width: 100%;
  }
}
</style>

<template>
  <div class="all">
    <div class="menu">
      <img class="menu-img" src="@/assets/img/tablogin.png" />
      <div class="menu-text">
        <el-menu class="el-menu-demo" mode="horizontal" :ellipsis="false">
          <el-menu-item index="1" @click="router.push('/')">首页</el-menu-item>
          <el-sub-menu
            v-for="item in menuList"
            :key="item.id"
            :index="`'${item.id}'`"
          >
            <template #title>{{ item.name }}</template>
            <el-menu-item
              v-for="each in item.childrens"
              :key="each.id"
              :index="`'${each.id}'`"
              @click="routerLink(each, item.name)"
              >{{ each.name }}</el-menu-item
            >
          </el-sub-menu>
          <el-menu-item index="wt" @click="openWt()">网上营业厅</el-menu-item>
        </el-menu>
      </div>
    </div>
    <div class="banner">
      <el-carousel motion-blur autoPlay>
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img :src="item.imgUrl" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 党建 -->
    <div class="news">
      <div class="header">
        <div class="title">鹤水党建</div>
        <div
          class="more"
          @click="
            router.push({
              path: 'Overview',
              query: { menuName: '鹤水党建', id: 51, pid: 2 },
            })
          "
        >
          了解更多
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div
            class="item"
            v-for="item in djList"
            :key="item.id"
            @click="detailLink(item, '鹤水党建', 51, 2)"
          >
            <span class="title">{{ item.title }}</span>
            <span class="time">{{ item.releaseTime }}</span>
          </div>
        </div>
        <div class="right">
          <el-carousel class="player" :interval="5000" arrow="never">
            <el-carousel-item v-for="item in djImgs" :key="item.id">
              <img class="img" :src="item.imgUrl" />
            </el-carousel-item>
            <el-carousel-item v-if="djImgs.length == 0">
              <div class="placeholder">暂无图片</div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- 党建 -->
    <!-- 公告 -->
    <div class="gg-news">
      <div class="header">
        <div class="title">鹤水公告</div>
        <div
          class="more"
          @click="
            router.push({
              path: 'Overview',
              query: { menuName: '鹤水公告', id: 53, pid: 5 },
            })
          "
        >
          了解更多
        </div>
      </div>
      <div class="gg">
        <div class="left">
          <div
            class="item"
            v-for="item in ggList"
            :key="item.id"
            @click="detailLink(item, '鹤水公告', 53, 5)"
          >
            <span class="title">{{ item.title }}</span>
            <span class="time">{{ item.releaseTime }}</span>
          </div>
        </div>
        <div class="right">
          <img class="img" src="@/assets/img/water.jpg" />
        </div>
      </div>
    </div>
    <!-- 公告 -->
    <!-- 信息 -->
    <div class="news">
      <div class="header">
        <div class="title">鹤水信息</div>
        <div
          class="more"
          @click="
            router.push({
              path: 'Overview',
              query: { menuName: '鹤水信息', id: 52, pid: 3 },
            })
          "
        >
          了解更多
        </div>
      </div>
      <div class="content">
        <div class="left">
          <div
            class="item"
            v-for="item in xxList"
            :key="item.id"
            @click="detailLink(item, '鹤水信息', 52, 3)"
          >
            <span class="title">{{ item.title }}</span>
            <span class="time">{{ item.releaseTime }}</span>
          </div>
        </div>
        <div class="right">
          <el-carousel class="player" :interval="5000" arrow="never">
            <el-carousel-item v-for="item in xxImgs" :key="item.id">
              <img class="img" :src="item.imgUrl" />
            </el-carousel-item>
            <el-carousel-item v-if="xxImgs.length == 0">
              <div class="placeholder">暂无图片</div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <!-- 信息 -->

    <!-- 内刊 -->
    <div class="paper">
      <div class="title">水务内刊</div>
      <div class="content" v-for="item in paperList" :key="item.id">
        <div class="left">
          <img :src="item.imgUrl" @click="openPdf(item)" />
        </div>
        <div class="right">
          <div class="html ql-editor" v-html="item.detail"></div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          small
          background
          layout="prev, pager, next"
          :total="pageTotal"
          :default-page-size="1"
          :current-page="pageIndex"
          @current-change="pageChange"
          class="mt-4"
        />
      </div>
    </div>
    <!-- 内刊 -->
    <!-- 水善润鹤城 -->
    <div class="waterhb">
      <div class="title">水善润鹤城</div>
      <div
        class="img"
        @click="
          router.push({
            path: '/Overview',
            query: { menuName: '鹤水党建', id: 54, pid: 2 },
          })
        "
      >
        <img src="@/assets/img/waterhb.jpg" />
        <div class="text">进入专题</div>
      </div>
    </div>
    <!-- 水善润鹤城 -->
  </div>
</template>

<script lang="ts" setup>
import { ref, inject } from "vue";
import { useRouter } from "vue-router";
import { GetMenuList, GetBannerList } from "@/api/home";
import { GetList, GetListDetail } from "@/api/page";

const openWt: any = inject("$openWt");
const router = useRouter();

let menuList: any = ref([]); //菜单列表
let bannerList: any = ref([]); //banner列表

getMenuList(); //获取菜单列表
getBannerList(); //获取banner列表51

//获取banner列表
function getBannerList() {
  GetBannerList()
    .then((res: any) => {
      bannerList.value = res.data.data.data;
    })
    .catch((err: any) => {
      console.log(err);
    });
}
//获取menu列表
function getMenuList() {
  GetMenuList()
    .then((res: any) => {
      menuList.value = res.data.data;
    })
    .catch((err: any) => {
      console.log(err);
    });
}

//路由跳转
const routerLink = function (item: any, menuName: any) {
  // console.log(item);
  router.push({
    path: "Overview",
    query: { menuName: menuName, id: item.id, pid: item.pid },
  });
  // router.push({path:`/home/${item.id}`})
};

let djList = ref<any>([]); //党建资讯列表
let djImgs = ref<any>([]); //党建资讯图片列表
getDjList(); //获取党建资讯

//获取党建资讯
function getDjList() {
  GetList({ cid: 51, p: 1 })
    .then((res: any) => {
      // console.log(res);
      let result = res.data.data.data;
      let imgList = result.filter((item: any) => {
        return item.imgUrl;
      });
      djList.value = result;
      djImgs.value = imgList;
      // console.log(djImgs);
    })
    .catch((err: any) => {
      console.log(err);
    });
}

let xxList = ref<any>([]); //集团新闻列表
let xxImgs = ref<any>([]); //集团新闻图片列表
getXxList(); //获取集团新闻图片

//获取集团新闻
function getXxList() {
  GetList({ cid: 52, p: 1 })
    .then((res: any) => {
      // console.log(res);
      let result = res.data.data.data;
      let imgList = result.filter((item: any) => {
        return item.imgUrl;
      });
      xxList.value = result;
      xxImgs.value = imgList;
      // console.log(djImgs);
    })
    .catch((err: any) => {
      console.log(err);
    });
}

let ggList = ref<any>([]); //鹤水公告(停水公告)列表
getGgList(); //获取鹤水公告(停水公告)图片

//获取鹤水公告
function getGgList() {
  GetList({ cid: 53, p: 1, pageSize: 6 })
    .then((res: any) => {
      // console.log(res);
      let result = res.data.data.data;
      ggList.value = result;
    })
    .catch((err: any) => {
      console.log(err);
    });
}

let paperList = ref<any>([]); //水务内刊列表
let pageIndex = ref<Number>(1); //当前页
let pageTotal = ref<Number>(0); //总条目数
getPaper(); //获取水务内刊
//水务内刊
function getPaper() {
  GetList({ cid: 50, p: pageIndex.value, pageSize: 1 })
    .then((res: any) => {
      // console.log(res);
      let result = res.data.data.data;
      pageTotal.value = res.data.data.total;

      if (result.length == 0) {
        return false;
      }
      getPaperDetail(result[0]);
    })
    .catch((err: any) => {
      console.log(err);
    });
}

//打开pdf
const openPdf = function (item: any) {
  window.open(item.coverImg);
};

//获取详细
function getPaperDetail(item: any) {
  let data = {
    id: item.id,
    cid: item.categoryId,
  };
  GetListDetail(data)
    .then((res: any) => {
      let result = res.data.data;
      paperList.value = [result];
    })
    .catch((err: any) => {
      console.log(err);
    });
}

//翻页
const pageChange = function (val: number) {
  pageIndex.value = val;
  getPaper();
};

//详情跳转
const detailLink = function (item: any, menuName: string, id: any, pid: any) {
  // console.log(item);
  // return false;
  router.push({
    path: "Overview",
    query: { menuName: menuName, id: id, pid: pid, detailId: item.id },
  });
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  .menu {
    z-index: 9999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.5rem;
    background-color: transparent;
    // background-color: #f88;
    display: flex;
    padding: 0 1rem;
    align-items: center;
    justify-content: space-between;
    .menu-img {
      // height: 0.24rem;
      width: 0.9rem;
      // margin-right: 0.6rem;
    }
  }
  .banner {
    // margin-bottom: 0.15rem;
    img {
      width: 100%;
      height: 100vh;
    }
  }
  .news {
    width: 100%;
    padding: 0.2rem 1rem;
    margin-bottom: 0.05rem;
    // background: url("@/assets/img/bgi2.png") no-repeat;
    // background-size: 100% 100%;
    // background-position: center;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.18rem;
      .title {
        font-size: 0.18rem;
        color: #183883;
      }
      .more {
        cursor: pointer;
        text-align: center;
        font-size: 0.08rem;
        width: 0.6rem;
        height: 0.2rem;
        line-height: 0.2rem;
        background-color: #183883;
        color: #fff;
        border-radius: 0.04rem;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      .left {
        width: 66%;
        .item {
          padding: 0 0.04rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 0.076rem;
          line-height: 0.2rem;
          border-bottom: 1px dashed #ccc;
          cursor: pointer;
          .title {
            width: 76%;
            color: #333;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all 0.3s;
          }
          &:hover .title {
            transition: all 0.3s;
            color: #409eff;
          }
          .time {
            color: #999;
          }
          &:hover .time {
            transition: all 0.3s;
            color: #409eff;
          }
        }
      }
      .right {
        .player {
          width: 2.2rem;
          height: 1.64rem;
          .img {
            width: 2.2rem;
            height: 1.64rem;
          }
          .placeholder {
            font-size: 0.18rem;
            font-weight: 600;
            color: #fff;
            background-color: #333;
            width: 2.2rem;
            height: 1.64rem;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
  .gg-news {
    width: 100%;
    padding: 0.2rem 1rem;
    margin-bottom: 0.15rem;
    background: url("@/assets/img/bgi1.jpg") no-repeat;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.18rem;
      .title {
        font-size: 0.18rem;
        color: #183883;
      }
      .more {
        cursor: pointer;
        text-align: center;
        font-size: 0.08rem;
        width: 0.6rem;
        height: 0.2rem;
        line-height: 0.2rem;
        background-color: #183883;
        color: #fff;
        border-radius: 0.04rem;
      }
    }
    .gg {
      display: flex;
      justify-content: space-between;
      .left {
        width: 78%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .item {
          width: 49%;
          height: 0.46rem;
          padding: 0.05rem 0.1rem;
          background-color: #f6f6f6;
          font-size: 0.076rem;
          line-height: 0.16rem;
          cursor: pointer;
          margin-bottom: 0.05rem;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

          .title {
            display: block;
            width: 100%;
            color: #555;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            transition: all 0.3s;
          }
          &:hover {
            transition: all 0.3s;
            background-color: #409eff;
          }
          &:hover .title {
            transition: all 0.3s;
            color: #fff;
          }
          .time {
            display: block;
            color: #999;
          }
          &:hover .time {
            transition: all 0.3s;
            color: #fff;
          }
        }
      }
      .right {
        img {
          width: 1.5rem;
          height: 1.485rem;
        }
      }
    }
  }
  .paper {
    width: 100%;
    padding: 0.2rem 1rem;
    // margin-bottom: 0.05rem;
    background-color: #eaf7fd;
    .title {
      font-size: 0.18rem;
      color: #183883;
      margin-bottom: 0.15rem;
    }
    .content {
      display: flex;
      justify-content: space-between;
      .left {
        img {
          cursor: pointer;
          border: 5px solid #ddd;
          display: block;
          width: 2.2rem;
          height: 3rem;
        }
      }
      .right {
        width: calc(100% - 2.6rem);
      }
    }
    .pagination {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.08rem;
    }
  }
  .waterhb {
    width: 100%;
    padding: 0.2rem 1rem;
    .title {
      font-size: 0.18rem;
      color: #183883;
      margin-bottom: 0.15rem;
    }
    .img {
      width: 100%;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
      }
      .text {
        width: 0.68rem;
        position: absolute;
        left: 0.5rem;
        bottom: 0.3rem;
        font-size: 0.08rem;
        background: linear-gradient(90deg, #ff8e08, #f65656);
        line-height: 0.22rem;
        color: #fff;
        font-weight: 600;
        text-align: center;
        border-radius: 1rem;
      }
    }
  }
}
</style>

<style scoped>
::v-deep(.el-carousel__container) {
  height: 100vh !important;
}
::v-deep(.el-menu) {
  background-color: transparent !important;
}
::v-deep(.el-menu .el-menu-item) {
  font-size: 0.08rem !important;
}
::v-deep(.el-menu--horizontal.el-menu) {
  border: none !important;
}
::v-deep(.el-menu--horizontal > .el-menu-item.is-active) {
  border: none !important;
  background-color: transparent !important;
}
::v-deep(.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title) {
  border: none !important;
}
::v-deep(.el-menu--horizontal > .el-sub-menu .el-sub-menu__title:hover) {
  /* color: #409eff !important; */
  background-color: transparent !important;
}
::v-deep(.el-menu--horizontal > .el-menu-item) {
  color: #fff !important;
}
::v-deep(.el-menu--horizontal > .el-sub-menu .el-sub-menu__title) {
  font-size: 0.08rem !important;
  color: #fff !important;
}
::v-deep(.el-menu--horizontal .el-menu-item:not(.is-disabled):hover) {
  /* color: #409eff !important; */
  background-color: transparent !important;
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
const app = createApp(App);

//初始样式
import '@/assets/css/index.css'
//公共样式
import '@/assets/css/public.css'
//富文本样式
import '@/assets/css/quill.snow.css'

//rem
import './assets/js/flex.js'

//饿了么ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

//pinia
import { createPinia } from 'pinia'

//全局组件
// import HelloWorld from '@/components/HelloWorld.vue'
// app.component('HelloWorld', HelloWorld)

//全局方法
import { GetDate} from '@/utils/other'
app.provide('$GetDate', GetDate);

import path from '@/utils/path'
app.provide('$uploadUrl', path.dev+"/api/FileUpload");

//打开网厅
const openWt = function () {
  window.open("http://yyt.hbswjt.net/wangting/index.html#/");
};
app.provide('$openWt', openWt);

app
.use(router)
.use(createPinia())
.use(ElementPlus)
.mount('#app')

// app.vue写在script里面  main.js写在app挂载完之后（莫名其妙的开局报错）
const debounce = (fn:any, delay:any) => {
    let timer:any
     return (...args:any) => {
       if (timer) {
         clearTimeout(timer)
       }
       timer = setTimeout(() => {
         fn(...args)
       }, delay)
     }
  }
    
  const _ResizeObserver = window.ResizeObserver;
  window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
     constructor(callback:any) {
       callback = debounce(callback, 200);
       super(callback);
     }
  }
import { PublicRequest } from '@/utils/PublicRequest'

//获取列表
function GetList(data:any){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/Article/lists',
            data:data,
            method: 'get',
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//获取列表
function GetListDetail(data:any){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/Article/index',
            data:data,
            method: 'get',
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

//获取详情
function GetDetail(data:any){
    return new Promise((resolve, reject) => {
        PublicRequest({
            path: '/api/About/index',
            data:data,
            method: 'get',
            loading: true,
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}



export {GetList,GetListDetail,GetDetail}